import React from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {
  DashboardLayout,
} from "../../lib/layouts";

import DashboardHeader from "../../config/DashboardHeader";
import SuperAdminMenu from "../../config/SuperAdminMenu";

import Home from "./Home";
import ProfilePage from "../Major/Profile";
import Establishments from "./Establishments";
import Establishment from "./Establishment";
import EstablishmentSettings from "./Establishment/Settings";
import EstablishmentMenu from "./Establishment/Menu";
import EstablishmentSubscription from "./Establishment/Subscription";
import EstablishmentStats from "./Establishment/statistics";

import CultureQuizz from "./CultureQuizz";
import CultureQuizzEdit from "./CultureQuizz/edit";
import Users from "./Users";
import User from "./User";
import Groupements from "./Groupements";
import Groupement from "./Groupements/Groupement";
import GroupementInfo from "./Groupements/Infos";
import GroupementAbonnement from "./Groupements/Abonnements";
import GroupementMenu from "./Groupements/Menu";
import GroupementSubscription from "./Groupements/Subscription";
import GroupementEstablishments from "./Groupements/Establishments";
import GroupementStats from "./Groupements/statistics";

import Bornes from "./Bornes";

import Patchnotes from "./Patchnotes";
import PatchnoteEdit from "./Patchnotes/patchnote/edit";
import PatchnoteView from "./Patchnotes/patchnote/view";

import Notifications from "./Notifications";
import Sandbox from "./Sandbox";
import ErrorReport from "./ErrorReport";
import Monitoring from "./Monitoring";

import TakeControl from "./Home/Redirect";
import { Provider } from "contexts/map.context";

import Logguer from "./Logguer/List";
import LogguerDetail from "./Logguer/LogguerDetails";

const MajorRouter = ({ match }) => {
  return (
    <Provider>
      <DashboardLayout menu={<SuperAdminMenu />} header={<DashboardHeader />}>
        <Switch>
          <Route exact path={`${match.url}/one`}><Home /></Route>
          <Route exact path={`${match.url}/profile`}><ProfilePage /></Route>
          <Route exact path={`${match.url}/establishments/:id/settings`}><EstablishmentSettings /></Route>
          <Route exact path={`${match.url}/establishments/:id/menu`}><EstablishmentMenu /></Route>
          <Route exact path={`${match.url}/establishments/:id/subscription`}><EstablishmentSubscription /></Route>
          <Route path={`${match.url}/establishments/:id/statistics`}><EstablishmentStats /></Route>
          <Route path={`${match.url}/establishments/:id`}><Establishment /></Route>
          <Route exact path={`${match.url}/groupements/:id/infos`}><GroupementInfo /></Route>
          <Route exact path={`${match.url}/groupements/:id/abonnements`}><GroupementAbonnement /></Route>
          <Route exact path={`${match.url}/groupements/:id/establishments`}><GroupementEstablishments /></Route>
          <Route exact path={`${match.url}/groupements/:id/statistics`}><GroupementStats /></Route>
          <Route exact path={`${match.url}/groupements/:id/menu`}><GroupementMenu /></Route>
          <Route exact path={`${match.url}/groupements/:id/subscription`}><GroupementSubscription /></Route>
          <Route exact path={`${match.url}/groupements/:id`}><Groupement /></Route>
          <Route path={`${match.url}/groupements`}><Groupements /></Route>
          <Route exact path={`${match.url}/establishments`}><Establishments /></Route>
          <Route exact path={`${match.url}/quizz`}><CultureQuizz /></Route>
          <Route exact path={`${match.url}/quizz/:uid`}><CultureQuizzEdit /></Route>
          <Route exact path={`${match.url}/users`}><Users /></Route>
          <Route exact path={`${match.url}/users/:uid`}><User /></Route>
          <Route exact path={`${match.url}/sandbox`}><Sandbox /></Route>
          <Route exact path={`${match.url}/reports`}><ErrorReport /></Route>
          <Route exact path={`${match.url}/bornes`}><Bornes /></Route>
          <Route exact path={`${match.url}/notifications`}><Notifications /></Route>
          <Route exact path={`${match.url}/patchnotes`}><Patchnotes /></Route>
          <Route exact path={`${match.url}/patchnotes/:uid/edit`}><PatchnoteEdit /></Route>
          <Route exact path={`${match.url}/patchnotes/:uid`}><PatchnoteView /></Route>
          <Route exact path={`${match.url}/monitoring`}><Monitoring /></Route>
          <Route exact path={`${match.url}/one/:id`}><TakeControl /></Route>
          <Route exact path={`${match.url}/logguer`}><Logguer /></Route>
          <Route exact path={`${match.url}/logguer/:id`}><LogguerDetail /></Route>
          <Route path={"*"}><Redirect to={`${match.url}/one`} /></Route>
        </Switch>
      </DashboardLayout>
    </Provider>
  );
};

MajorRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MajorRouter;