import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import useUI from "../../../../../../hooks/ui.hook";

import { useMenuReservation } from "../../router_context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Edit, Home, PlusCircle } from "react-feather";

import Table from "../../../detailedTable";
import moment from "moment";
import { Button } from "lib/components";

import { ModalReservation, ModalWeek } from "../../router_components";

import deepClone from "helpers/deepClone";
import {findSubscriptionForDate} from "helpers/subscription"

const ReservationTable = (props) => {
  const { 
    showTotal = false
  } = props;
  const [ctx] = useMenuReservation();
  const [ui] = useUI();
  const [columns, setColumns] = useState([]);
  // const [showTotal, setShowTotal] = useState(false);
  const [organisedData, setOrganisedData] = useState();
  const [userId, setUserId] = useState(null);

  const template = ui.establishment.template;
  const templateSubscription = ui.establishment.templateSubscription;
  
  const totalRepasDay = (_date, _repas) => {
    if (ctx.data && ctx.data[_date] && ctx.data[_date]?.reservation) {
      let retour = 0;

      for (const key in ctx.data[_date]?.reservation) {
        if (!Object.keys(ctx.filteredUsers).includes(key)) continue;
        const _user = ctx.data[_date]?.reservation[key];
        if (_user && _user[_repas] && !(_user[_repas][0] && _user[_repas][0].status === "canceled")) {
          retour += _user[_repas].length;
        }
      }

      if (retour === 0) retour = " ";
      return retour;
    } else {
      return " ";
    }
  };

  const isSupplement = (userId, data, _repas, date) => {
    const _user = ctx.filteredUsers[userId];

    let retour = true;

    const _subscription = findSubscriptionForDate(_user?.subscriptions, date);

    if (template && templateSubscription) {
      if (_subscription && _subscription?.subscription) {
        // L'utilisateur a un abonnement pour ce mois.

        // si le repas est pris en compte dans l'inscription
        if (templateSubscription[_subscription?.subscription].repas[_repas]) {

          // On considère qu'il y a un supplément dans le cas où il y a plus de catégories réservés que de catégories
          // dans l'abonnement
          // ON ne prend PAS en compte les invitations. 
          let countResa = 0;

          template[_repas].categories.forEach((_categ) => {
            if (data[_repas][0][_categ] != null) {
              countResa++;
            }
          });

          if (countResa > templateSubscription[_subscription?.subscription].repas[_repas].length) {
            retour = true;
          } else {
            retour = false;
          }

        } else {
          retour = false;
        }

      } else {
        retour = false;
      }
    } else {
      retour = false;
    }


    return retour;
  };


  useEffect(() => {
    if (ctx.dateArray) {
      let repas = Object.keys(ui.establishment.template).map(i => ({ ...ui.establishment.template[i], name: i })).sort((a, b) => a.heure - b.heure).map(i => i.name);

      let _columns = [];

      const _subColumns = [{
        Header: "Nom",
        Footer: "TOTAL",
        accessor: "name",
        filter: "fuzzyText",
        sticky: "left",
        width: 200,
      }];

      if (ui.establishment.templateSubscription) {
        _subColumns.push({
          Header: "abonnement",
          Footer: " ",
          accessor: "subscription",
          width: 120,
        });
      }
      _subColumns.push({
        Header: "détails",
        Footer: " ",
        accessor: "details",
        disableSortBy: true,
        width: 60,
        Cell: CellDetails
      });


      _columns.push({
        Header: "",
        Footer: "",
        accessor: "search",
        sticky: "left",
        disableSortBy: true,
        columns: _subColumns
      });


      // initialisation des utilisateurs
      let organisedDataDict = {};
      Object.entries(ctx.filteredUsers).forEach(([uid, _userData]) => {
        let name = `${_userData?.name} ${_userData?.surname} ${_userData?.room ?? ""}`;
        if (organisedDataDict[name] == undefined) {
          organisedDataDict[name] = {};
        }
        organisedDataDict[name].userId = uid;
        if (templateSubscription){

          const subArray = [];
          ctx.dateArray.forEach(_date => {
            const _subscription = findSubscriptionForDate( ctx.filteredUsers[uid]?.subscriptions, _date);
            if(_subscription){
              if(subArray.indexOf(_subscription?.subscription) === -1){
                subArray.push(_subscription?.subscription);
              }
            }
          });
          
          organisedDataDict[name]["subscription"] = subArray.join(", ");
        }
      });

      const sumTotaux = {
        "Total_repas": 0,
        "Total_portage": 0,
        "Total_invitations": 0,
        "Total_supplement": 0,
      };

      repas.forEach((repasKey) => {
        sumTotaux["Total_" + repasKey] = 0;
      });

      // on boucle sur les jours
      ctx.dateArray.forEach((_date, index) => {

        // on commence par ajouter les colonnes 
        let _underColumns = [];

        repas.forEach((repasId, indx) => {
          _underColumns.push(
            {
              Header: repasId,
              Footer: `${totalRepasDay(_date, repasId)}`,
              accessor: _date + "-" + repasId,
              filter: "fuzzyText",
              disableSortBy: false,
              width: 100,
              Cell: Cell,
              date: _date,
              repas: repasId,
              colorChange: index * repas.length + indx
            }
          );
        });

        if (!showTotal) {
          _columns.push({
            Header: moment(_date).format("ddd DD"),
            Footer: "",
            disableSortBy: true,
            columns: _underColumns
          });
        }




        //on s'occupe des lignes
        let day = ctx.data[_date];
        if (day && day.reservation) {
          Object.entries(day.reservation).forEach(([resaUserId, resa]) => {
            if (ctx.filteredUsers[resaUserId]) {
              organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`].userId = resaUserId;

              if (!organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_repas"]) organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_repas"] = 0;

            }

            repas.forEach((repasKey) => {
              if (ctx.filteredUsers[resaUserId] && resa[repasKey]) {


                organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`][`${_date}-${repasKey}`] = {
                  userId: resaUserId,
                  nbResas: resa[repasKey].length,
                  homeDelivery: resa[repasKey][0].homeDelivery,
                  status: resa[repasKey].length > 0 ? resa[repasKey][0].status ?? "valid" : null,
                };

                if (!(resa[repasKey][0] && resa[repasKey][0].status === "canceled")) {
                  organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_repas"] += resa[repasKey].length;
                  sumTotaux["Total_repas"] += resa[repasKey].length;

                  if (!organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_" + repasKey]) organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_" + repasKey] = 0;
                  organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_" + repasKey] += resa[repasKey].length;
                  sumTotaux["Total_" + repasKey] += resa[repasKey].length;

                  if (resa[repasKey][0].homeDelivery) {
                    if (!organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_portage"]) organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_portage"] = 0;
                    organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_portage"] += 1;
                    sumTotaux["Total_portage"] += 1;
                  }

                  if (resa[repasKey].length > 1) {
                    if (!organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_invitations"]) organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_invitations"] = 0;
                    organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_invitations"] += resa[repasKey].length - 1;
                    sumTotaux["Total_invitations"] += resa[repasKey].length - 1;
                  }


                  if (isSupplement(resaUserId, resa, repasKey, moment(_date))) {
                    if (!organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_supplement"]) organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_supplement"] = 0;
                    organisedDataDict[`${ctx.filteredUsers[resaUserId].name} ${ctx.filteredUsers[resaUserId].surname} ${ctx.filteredUsers[resaUserId].room ?? ""}`]["Total_supplement"] += 1;
                    sumTotaux["Total_supplement"] += 1;
                  }

                }


              }
            });


          });
        }
      });

      const _underColumnsTotal = [];

      _underColumnsTotal.push(
        {
          Header: "Repas",
          Footer: sumTotaux["Total_repas"] > 0 ? sumTotaux["Total_repas"] : " ",
          accessor: "Total_repas",
          sticky: "right",
          width: 60,
        }
      );

      repas.forEach((repasId, indx) => {
        _underColumnsTotal.push(
          {
            Header: repasId,
            Footer: sumTotaux["Total_" + repasId] > 0 ? sumTotaux["Total_" + repasId] : " ",
            accessor: "Total_" + repasId,
            disableSortBy: false,
            sticky: "right",
          }
        );
      });

      _underColumnsTotal.push(
        {
          Header: "Portage",
          Footer: sumTotaux["Total_portage"] > 0 ? sumTotaux["Total_portage"] : " ",
          accessor: "Total_portage",
          sticky: "right",
        }
      );
      _underColumnsTotal.push(
        {
          Header: "Invitations",
          Footer: sumTotaux["Total_invitations"] > 0 ? sumTotaux["Total_invitations"] : " ",
          accessor: "Total_invitations",
          sticky: "right",
          width: 100,
        }
      );
      if (ui.establishment.templateSubscription) {
        _underColumnsTotal.push(
          {
            Header: "Supplément",
            Footer: sumTotaux["Total_supplement"] > 0 ? sumTotaux["Total_supplement"] : " ",
            accessor: "Total_supplement",
            sticky: "right",
            width: 100,
          }
        );
      }


      if (showTotal) {
        _columns.push({
          Header: "Totaux",
          Footer: "",
          disableSortBy: true,
          sticky: "right",
          columns: _underColumnsTotal
        });
      }
      //format rows
      let _organisedData = [];
      Object.entries(organisedDataDict).sort((a,b) => a[0].localeCompare(b[0])).forEach(([key, value]) => {
        let obj = value;
        obj.name = key;
        _organisedData.push(obj);
      });

      setOrganisedData(_organisedData);
      setColumns(_columns);
    }
  }, [ctx.dateArray, ctx.data, ctx.filteredUsers, showTotal]);

  return (
    <div>
      <ModalReservation />
      <ModalWeek />
      <Table _columns={columns} _data={organisedData} colWidth={80} hasFooter={true} onClick={() => (null)} />
      {/* <UsersDetails uid={userId} retour={() => setUserId(null)} week={data ? Object.keys(data)[0] : null} onlyMenu={true}/> */}
    </div>
  );
};

ReservationTable.propTypes = {
  showTotal: PropTypes.bool.isRequired,
};



const CellDetails = ({ cell }) => {
  const [ctx, dispatch] = useMenuReservation();

  const clickUser = () => {
    dispatch({ type: "setProperty", property: "modalWeekOpen", value: true });
    dispatch({
      type: "setProperty", property: "modalInfos", value: {
        uid: cell.row.original.userId,
        user: cell.row.original.name,
      }
    });
    //alert("coucou");
    //setUserId(uid);
  };

  return <div style={{ height: 91, display: "flex", justifyContent: "center", alignItems: "center" }}>
    <FontAwesomeIcon
      icon={faSearch}
      style={{ "cursor": "pointer" }}
      onClick={() => clickUser(cell.row.original.userId)}
    />
  </div>;
};
CellDetails.propTypes = {
  cell: PropTypes.object.isRequired,
};

const Cell = ({ cell }) => {
  const [ctx, dispatch] = useMenuReservation();
  const [homeDelivery, setHomeDelivery] = useState(false);
  let value = cell.value;

  useEffect(() => {
    if (value && value.homeDelivery == true) setHomeDelivery(true);
    if ((value && value.homeDelivery == false) || (!value && homeDelivery)) setHomeDelivery(false);
  }, [cell]);

  const updateHomeDelivery = () => {
    if (ctx?.data) {
      try {
        let _date = cell.column.date;
        let _uid = cell.row.original.userId;
        let _repas = cell.column.repas;

        let _resa = deepClone(ctx.data[_date].reservation[_uid][_repas]);

        _resa.forEach(element => {
          element.homeDelivery = !homeDelivery;
        });

        ctx.updateMenu(_resa, _date, _uid, _repas);

      } catch (error) {
        //none
      }
    }
  };

  const clickCell = (cell) => {
    if (!ctx?.data[cell.column.date] || (!ctx?.data[cell.column.date][cell.column.repas])) return;
    console.log(cell);
    dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
    dispatch({
      type: "setProperty", property: "modalInfos", value: {
        uid: cell.row.original.userId,
        date: cell.column.date,
        repas: cell.column.repas,
      }
    });
  };

  const isDisabled = !ctx?.data[cell.column.date] || !ctx?.data[cell.column.date][cell.column.repas];

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      width: 100, height: 91,
      padding: 10,
      position: "relative",
      cursor: isDisabled ? "not-allowed" : "pointer"
    }}
      onClick={() => clickCell(cell)}>
      <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
        <div style={{ fontSize: 22, color: value && value.status === "canceled" ? "orange" : value && value.status === "valid" ? "green" : "black" }}>
          {value ? value.nbResas ?? 0 : 0}
        </div>
        <div style={{ position: "absolute", right: 0, top: 0, border: "1px solid #ccc", borderBottomLeftRadius: 5, padding: 3, }}>
          {value?.nbResas ?
            <Edit
              size={12}
              style={{ "cursor": isDisabled ? "not-allowed" : "pointer", width: 20, height: 20 }}
              color={value && value.status === "canceled" ? "orange" : value && value.status === "valid" ? "green" : "#6d0000<s"}
              onClick={() => clickCell(cell)}
            />
            :
            <PlusCircle
              size={12}
              style={{ "cursor": isDisabled ? "not-allowed" : "pointer", width: 20, height: 20 }}
              color={value && value.status === "canceled" ? "orange" : value && value.status === "valid" ? "green" : "#6d6d6d"}
              onClick={() => clickCell(cell)}
            />
          }
        </div>
      </div>
      {/*<Checkbox label="port." checked={homeDelivery} onChange={updateHomeDelivery}/>*/}
      <div style={{ width: 60 }}>
        {homeDelivery && <Home />}
      </div>
    </div>
  );
};

Cell.propTypes = {
  cell: PropTypes.object.isRequired,
};


export default ReservationTable;
