import { Input } from "@/lib/components";
import React, { useState, useEffect } from "react";
import LogoJabi from "assets/images/jabiLogo/Logo_MajorByJabi.png";
import Illustration from "assets/images/DEMO_ILLUSTRATION.png";
import TotemImage from "assets/images/totem.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faWifi } from "@fortawesome/free-solid-svg-icons";
import useMicroService from "helpers/microService";
import { Spinner } from "@/lib/components";
import firebase from "firebase";
import ReactPlayer from "react-player";
import { ReactSVG } from "react-svg";
import IllustrationDemo from "assets/svg/IllustrationDemo.svg";
import IllustrationDemo2 from "assets/svg/IllustrationDemo2.svg";
import IllustrationDemo3 from "assets/svg/IllustrationDemo3.svg";
import styles from "assets/scss/pages/loginDemo.module.scss";

const firestore = firebase.firestore;


const DemoForm = ({ style }) => {
  const execMicroService = useMicroService();
  const [formData, setFormData] = useState({
    "name": "",
    "surname": "",
    "mail": "",
    "phone": "",
    "establishmentName": "",
  });
  const [demoRequestSucceed, setDemoRequestSucceed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, []);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?(\d{1,3})?[-.\s]?(\d{2,3})?[-.\s]?(\d{2,4})[-.\s]?(\d{2,4})[-.\s]?(\d{2,4})$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = async () => {
    const { ["phoneerror"]: _, ["mailerror"]: __, ...freshFormData } = formData;
    let error = false;

    if (!formData.name) {
      freshFormData["nameerror"] = "Veuillez renseigner votre nom.";
      error = true;
    }


    if (!formData.surname) {
      freshFormData["surnameerror"] = "Veuillez renseigner votre prénom.";
      error = true;
    }

    if (!validateEmail(formData.mail)) {
      freshFormData["mailerror"] = "Veuillez renseigner une adresse email valide.";
      error = true;
    }

    if (!validatePhoneNumber(formData.phone)) {
      freshFormData["phoneerror"] = "Veuillez renseigner un numéro de numéro de téléphone valide.";
      error = true;
    }

    if (!formData.establishmentName) {
      freshFormData["establishmentNameerror"] = "Veuillez renseigner le nom de votre établissement.";
      error = true;
    }

    console.log(freshFormData);
    setFormData({ ...freshFormData });
    if (error) return;
    setLoading(true);


    const res = await execMicroService("createDemo", formData);
    console.log("res", res);
    if (res.status !== 200) {
      setError(res?.data?.error);
      setLoading(false);
      return;
    }
    setDemoRequestSucceed(true);
    setError(null);
    setLoading(false);
  };

  return (
    <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#fefefe", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", padding: 20, gap: 10, ...style }}>
      <div style={{fontWeight: "bold", fontSize: 32}}>Vos accès à Major en 1 clic</div>

      {/* Prénom Nom */}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
        <InputLocal style={{ flex: 1 }} value={formData.surname} onChange={e => setFormData({ ...formData, surname: e.target.value })} label={"Prénom"} placeholder={"Prénom"} error={formData.surnameerror} />
        <InputLocal style={{ flex: 1 }} value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} label={"Nom"} placeholder={"Nom"} error={formData.nameerror} />
      </div>

      {/* Email Téléphone Nom Etablissement */}
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <InputLocal value={formData.mail} onChange={e => setFormData({ ...formData, mail: e.target.value })} label="Email" placeholder={"Email"} error={formData.mailerror} />
        <InputLocal value={formData.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} label={"Téléphone"} placeholder={"Téléphone"} error={formData.phoneerror} />
        <InputLocal value={formData.establishmentName} onChange={e => setFormData({ ...formData, establishmentName: e.target.value })} label={"Nom de l'etablissement"} placeholder={"Nom de l'etablissement"} error={formData.establishmentNameerror} />
      </div>
      <div>
        <div
          onClick={() => !demoRequestSucceed && onSubmit()}
          style={{ cursor: "pointer", backgroundColor: demoRequestSucceed ? "#4eb699" : "#510059", color: "white", padding: 10, textAlign: "center", borderRadius: 5, marginTop: 15, fontSize: 16, fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}
        >
          {demoRequestSucceed ? "Je reçois mes accès" : "Recevoir mes accès"}

          {loading && <div style={{ position: "absolute", right: 10 }}>
            <Spinner size="md" />
          </div>}
        </div>
        {demoRequestSucceed && <div style={{ color: "#4eb699", fontWeight: "bold", marginTop: 15 }}>Vous recevrez très prochainement vos accès à votre compte Major, par mail.</div>}
        {error && <div style={{ color: "red", marginTop: 15 }}>{error}</div>}

      </div>
      <ReactSVG src={IllustrationDemo3} style={{position: "absolute", right: -300, bottom : -50}}size={200}/>

    </div>
  )
};

const AdventageItem = ({ icon, title, description }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 15, alignItems: "center" }}>
      <FontAwesomeIcon
        size={"3x"}
        icon={icon}
        color={"#4eb699"}
      />
      <div>
        <div style={{ fontWeight: "bold", color: "#510059", fontSize: 28 }}>{title}</div>
        <div style={{ color: "black", fontSize: 22 }}>{description}</div>
      </div>
    </div>
  );
};

const InputLocal = ({ style, value, onChange, label, placeholder, error }) => {
  return (
    <div style={{ ...style }}>
      {label && <div style={{ fontWeight: "bold" }}>{label}</div>}
      <Input value={value} onChange={onChange} placeholder={placeholder} />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  )
}

const playIcon = () => {
  return (
    <div style={{ position: "absolute", backgroundColor: "transparent", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <div style={{backgroundColor: "#1c0028", opacity: 0.8, width: 50, height: 50, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 150}}>
        <FontAwesomeIcon
          size={"1x"}
          icon={faPlay}
          color={"white"}
        />
      </div>
      <div style={{position: "absolute", bottom: 5, backgroundColor: "#1c0028", opacity: 0.8, width: "90%", borderRadius: 15, padding: 10}}>
        <div style={{color: "white", fontSize: 16, fontWeight: "bold"}}>Témoignage 2023</div>
        <div style={{color: "lightgrey"}}>Ils nous parlent de Major</div>
      </div>
    </div>
  )
};

const LoginDemoPage = () => {
  const [playing, setPlaying] = useState(false);
  useEffect(() => {

    const geojsUrl = 'https://get.geojs.io/v1/ip/geo.json';
            
    fetch(geojsUrl)
        .then(response => response.json())
        .then(data => {
            firestore().collection("push-data").doc("push-data").update(
              {
                count: firebase.firestore.FieldValue.increment(1),
                infos: firebase.firestore.FieldValue.arrayUnion(
                  {
                    date: new Date,
                    ...data
                  }
                )
              });
      
        })
        .catch(error => {
          firestore().collection("push-data").doc("push-data").update(
            {
              count: firebase.firestore.FieldValue.increment(1),
              infos: firebase.firestore.FieldValue.arrayUnion(
                new Date()
              )
            });
    
        });
   

    return () => {

    };
  }, []);

  return (
    <div style={{ height: "100vh", backgroundColor: "#ece6f0", overflow: "auto"}}>
      <div id="header" style={{ display: "flex", justifyContent: "center", paddingTop: "2vh", paddingBottom: "5vh", position: "fixed", width: "100%" }}>
        <img src={LogoJabi} alt="logo" style={{ height: "12vh" }} />
      </div>
      <div id="content" style={{ height: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", width: "100%", justifyContent: "space-around", backgroundColor: "#ece6f0"}}>
        <div id="left" style={{maxWidth: 700, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "transparent", gap: 40, paddingRight: 50}}>
          <div id="hero-container" style={{position: "relative", width: "100%"}}>
            
            <div style={{width: 400, fontSize: 36, fontWeight: "bold", color: "#1c0028", textAlign: "left"}}>
              Gérez efficacement les services de votre établissement sénior !
            </div>
            <ReactSVG src={IllustrationDemo2} style={{ position: "absolute", zIndex: 10, bottom: 0, left: 0}} />

          </div>
          <DemoForm style={{ width: "100%" }} />
        </div>
        <div id="right" style={{zIndex: 2, maxWidth: 700, marginRight: 200, marginTop: "-15vh", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", backgroundColor: 'transparent'}}>
          <div style={{display: "flex", width: "26vw"}}>
            <div style={{flex: 1}}/>
            <FontAwesomeIcon icon={faWifi} size={"3x"} color={"#763981"} style={{rotate: "45deg"}} />

          </div>
          <div style={{width: "20vw", height: "20vw", minWidth: 250, minHeight: 250, overflow: "hidden", borderRadius: 15, position: "relative"}}>
            <ReactPlayer 
              url={"https://www.youtube.com/watch?v=-8bo0hglgto"} 
              width={"100%"} 
              height={"100%"}
              className={styles.player}
              controls={true} 
              light={<img src="https://img.youtube.com/vi/-8bo0hglgto/maxresdefault.jpg" alt="Thumbnail" className={styles.thumbnail}/>} 
              playIcon={playIcon()} 
              onClickPreview={() => {setPlaying(playing); console.log("clicked")}} 
              playing={playing} 
              onPlay={() => setPlaying(true)} 
              onPause={() => setPlaying(false)} 
            />
          </div>
        </div>
        
      </div>
      <img src={TotemImage} alt="borne" style={{ position: "absolute", bottom: 0, right: 0 }} className={styles.borne}/>
      <ReactSVG src={IllustrationDemo} style={{ position: "absolute", top: 0, right: 0, zIndex: 10 }} size={200} />
    </div>

  )
};

export default LoginDemoPage;