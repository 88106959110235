import React from "react";

import Ovelia_UseMenu, {Provider as Ovelia_MenuProvider} from "./Specific/ovelia/Contexts/context";
import useUI from "@/hooks/ui.hook";


const Provider = (props) => {
  const [ui] = useUI();
  if (ui?.groupement?.specific === "ovelia") return <Ovelia_MenuProvider {...props} />;
  return <>{props.children}</>
}

const useMenu = (props) => {
  const [ui] = useUI();

  if (ui?.groupement?.specific === "ovelia") return <Ovelia_UseMenu {...props} />;
  return () => {};
}

export { useMenu, Provider };